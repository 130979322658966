import React, { useEffect, useContext, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Collapse from "@material-ui/core/Collapse"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import CardTravelIcon from "@material-ui/icons/CardTravel"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import AllInboxIcon from "@material-ui/icons/AllInbox"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import GetAppIcon from "@material-ui/icons/GetApp"
import HomeIcon from "@material-ui/icons/Home"
import PublishIcon from "@material-ui/icons/Publish"
import { GlobalContext } from "@Context/context/GlobalState"
import { useTranslation } from "react-i18next"
import ClientComponent from "@Components/Client"
import ButtonSidebarNav from '@Components/Shared/ButtonSidebarNav';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

const ListItems = (props) => {
  const { canISeeGroups } = props
  const { t } = useTranslation("global")
  const { openDrawer, handleDrawer } = useContext(GlobalContext)
  const classes = useStyles()
  const [ordersLevel, setOrdersLevel] = useState(false)
  const [logisticLevel, setLogisticLevel] = useState(false)
  const [inventoryLevel, setInventoryLevel] = useState(false)

  useEffect(() => {
    if (!openDrawer && (ordersLevel || logisticLevel || inventoryLevel)) {
      setOrdersLevel(false)
      setLogisticLevel(false)
      setInventoryLevel(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDrawer])

  useEffect(() => {
    if ((ordersLevel || logisticLevel || inventoryLevel) && !openDrawer) {
      handleDrawer()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordersLevel, logisticLevel, inventoryLevel])

  const MassiveLoadsButton = () => (
    <ButtonSidebarNav
      route="/massive_loads"
      name="massive-loads"
      icon={PublishIcon}
    />
  )

  // const SGAcceptButton = () => {
  //   <ButtonSidebarNav
  //     route="/shipping_groups_accept"
  //     name="detail-units-accepted"
  //   />
  // }

  const ScheduleButton = () => (
    <ButtonSidebarNav
      route="/schedule"
      name="schedule"
    />
  )

  return (
    <List component="nav" className={classes.root}>
      <ButtonSidebarNav
        route="/dashboard"
        name="home"
        icon={HomeIcon}
      />
      {canISeeGroups.orders && <ListItem button onClick={() => setOrdersLevel(!ordersLevel)}>
        <ListItemIcon>
          <CardTravelIcon />
        </ListItemIcon>
        <ListItemText primary={t("orders")} />
        {ordersLevel ? <ExpandLess /> : <ExpandMore />}
      </ListItem>}
      <Collapse in={ordersLevel} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ButtonSidebarNav
            name="detail-orders"
            route="/orders"
          />
          <ButtonSidebarNav
            route="/shipping_groups"
            name="detail-shipping-groups"
          />
          <ClientComponent
            SandboxDev={
              <ButtonSidebarNav
                route="/orders/select-new"
                name="create-order"
              />
            }
          />
        </List>
      </Collapse>
      {canISeeGroups.logistic && <ListItem button onClick={() => setLogisticLevel(!logisticLevel)}>
        <ListItemIcon>
          <LocalShippingIcon />
        </ListItemIcon>
        <ListItemText primary={t("logistics")} />
        {logisticLevel ? <ExpandLess /> : <ExpandMore />}
      </ListItem>}
      <Collapse in={logisticLevel} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ClientComponent
            CasaideasDev={<ScheduleButton />}
            CasaideasUat={<ScheduleButton />}
            CasaideasProd={<ScheduleButton />}
            LogytechDev={<ScheduleButton />}
            LogytechUat={<ScheduleButton />}
            SodimacDev={<ScheduleButton />}
            SandboxDev={<ScheduleButton />}
            SandboxUat={<ScheduleButton />}
          />
          <ButtonSidebarNav
            name="sources"
            route="/sources"
          />
          <ButtonSidebarNav
            name="couriers"
            route="/couriers"
          />
          <ButtonSidebarNav
            name="capacity-used"
            route="/use-capacity"
          />
          <ButtonSidebarNav
            name="routes"
            route="/routes"
          />
          <ButtonSidebarNav
            name="coverage"
            route="/coverage"
          />
          <ButtonSidebarNav
            name="locations"
            route="/locations"
          />
          <ButtonSidebarNav
            name="calendar"
            route="/calendar"
          />
        </List>
      </Collapse>
      {canISeeGroups.inventory && <ListItem button onClick={() => setInventoryLevel(!inventoryLevel)}>
        <ListItemIcon>
          <AllInboxIcon />
        </ListItemIcon>
        <ListItemText primary={t("inventory")} />
        {inventoryLevel ? <ExpandLess /> : <ExpandMore />}
      </ListItem>}
      <Collapse in={inventoryLevel} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ButtonSidebarNav
            name="sales-channels"
            route="/channels"
          />
          <ButtonSidebarNav
            name="stock"
            route="/stock"
          />
          <ButtonSidebarNav
            name="articles"
            route="/items"
          />
        </List>
      </Collapse>
      {canISeeGroups.massiveLoads && <ClientComponent
        AdretailDev={<MassiveLoadsButton />}
        AdretailUat={<MassiveLoadsButton />}
        CasaideasDev={<MassiveLoadsButton />}
        CasaideasUat={<MassiveLoadsButton />}
        SandboxDev={<MassiveLoadsButton />}
        SandboxUat={<MassiveLoadsButton />}
        LogytechUat={<MassiveLoadsButton />}
        NaveDev={<MassiveLoadsButton />}
        SodimacDev={<MassiveLoadsButton />}
      />}
      {canISeeGroups.reports && <ButtonSidebarNav
        name="reports"
        route="/dowloadReports"
        icon={GetAppIcon}
      />}
    </List>
  )
}

export default ListItems
