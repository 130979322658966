import React, { useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalState"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import HomeIcon from "@material-ui/icons/Home"
import { useTranslation } from "react-i18next"
import OmnixLogo from "@Img/omnix-logo.svg"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import fondoNeutral from "@Img/login/fondo-neutral.png"
// import Card from "@material-ui/core/Card"
// import { getListSearchables } from "@Services/osrmServices"
// import { styled } from "@material-ui/styles"
// import Skeleton from '@material-ui/lab/Skeleton';

// const RootStyle = styled(Card)(({ theme }) => ({
//   boxShadow: "none",
//   width: 300,
//   textAlign: "left",
//   padding: 30,
//   textTransform: "capitalize"
// }))

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundPosition: "bottom center",
    backgroundSize: "100% 70%",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${fondoNeutral})`,
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    padding: 60,
    minHeight: 600,
    textAlign: "center",
  },
  logo: {
    minWidth: "450px",
    maxWidth: "900px",
    paddingTop: 40,
  },
}))

// const TOKEN = "S1Rl5lBvjBa0EiHUdPzy14Qpv7XSYgjV83luJ6vL"

const HomeScreen = () => {
  const classes = useStyles()
  // const initialValues = Array.from(Array(10).keys())
  // const [info, setInfo] = useState(initialValues)
  // const [load, setLoad] = useState(true)
  const { t } = useTranslation("global")
  const {
    handleRoutingBreadcrumbs,
    // handleErrorSession
  } = useContext(GlobalContext)

  useEffect(() => {
    handleRoutingBreadcrumbs({
      titleHeader: t("home"),
      routes: [{ name: t("home"), route: "/dashboard" }],
      icon: <HomeIcon style={{ marginRight: 5, opacity: 0.5 }} />,
    })
    // setLoad(true)
    // loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const loadData = async () => {
  //   try {
  //     let response = await getListSearchables(TOKEN)
  //     setInfo(response?.data?.message?.records)
  //     setLoad(false)
  //   } catch (error) {
  //     handleErrorSession({
  //       error: true,
  //       errorCode: error.response.status,
  //       messageError: error?.response?.data?.message,
  //     })
  //   }
  // }

  // const Card = ({entity}) => {
  //   const [entityName] = Object.keys(entity);
  //    return (
  //     <RootStyle>
  //       {!load ? <Typography variant="h5" sx={{ opacity: 0.72 }}>
  //         {entity[entityName]}
  //       </Typography> : <Skeleton variant="text" width={40} />}
  //       {!load ? <Typography variant="subtitle2">{t(entityName)}</Typography> : <Skeleton variant="text" width={80} />}
  //       <hr />
  //     </RootStyle>
  //   )
  // }

  return (
    <Paper className={classes.root}>
      <Container className={classes.mainContainer}>
        <div className={classes.titleWithImg}>
          <Typography variant="h3">
            {t("welcome-to")}<b> SIMO</b>
          </Typography>
          <img src={OmnixLogo} alt="Omnix" className={classes.logo} />
        </div>
        {/* <div style={{
          display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap",
          }}>
          {info.map((val, index) => (
            <Card entity={val} key={index} />
          ))}
        </div> */}
      </Container>
    </Paper>
  )
}

export default HomeScreen
